<template>
  <form>
    <b-form-select
      v-model="educationalUnitKey"
      :options="educationalUnitOptions"
      :plain="true"
      @change="handleSelectChange"
      class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0"
    >
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump-educational-unit'].tcSelectAnotherEducationalUnit }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump-educational-unit',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-educational-unit': {
            tcSelectAnotherEducationalUnit: 'Select another Educational Unit'
          }
        }
      }
    },
    educationalUnitKeyProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      educationalUnitKey: this.educationalUnitKeyProp
    }
  },
  computed: {
    ...mapGetters({
      educational_units: 'conversations/educational_units',
    }),
    educationalUnitOptions() {
      return this.educational_units.map((el) => {
        return {
          text: el.name,
          value: el.ind_key
        }
      })
    },
  },
  methods: {
    ...mapActions({
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async handleSelectChange() {
      await Promise.all([await this.setSelectedIndividualKey(this.educationalUnitKey)]).then(
        () => {
          this.$emit('educational_unit_changed')
        }
      )
    }
  }
}
</script>

<style/>